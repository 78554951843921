// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

.login-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Fixed width for the card */
}

.login-input {
  border: 1px solid black; /* Black border for input */
  color: black;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%; /* Full width */
  border-radius: 4px; /* Rounded corners for input */
}

.login-button {
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Full width for button */
}

.login-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,gCAAgC;AACjD;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,YAAY,EAAE,6BAA6B;AAC7C;;AAEA;EACE,uBAAuB,EAAE,2BAA2B;EACpD,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,WAAW,EAAE,eAAe;EAC5B,kBAAkB,EAAE,8BAA8B;AACpD;;AAEA;EACE,yBAAyB,EAAE,4BAA4B;EACvD,YAAY,EAAE,sBAAsB;EACpC,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,WAAW,EAAE,0BAA0B;AACzC;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD","sourcesContent":[".login-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full height of the viewport */\n}\n\n.login-card {\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  width: 300px; /* Fixed width for the card */\n}\n\n.login-input {\n  border: 1px solid black; /* Black border for input */\n  color: black;\n  padding: 10px;\n  margin-bottom: 15px;\n  width: 100%; /* Full width */\n  border-radius: 4px; /* Rounded corners for input */\n}\n\n.login-button {\n  background-color: #007bff; /* Button background color */\n  color: white; /* Button text color */\n  padding: 10px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  width: 100%; /* Full width for button */\n}\n\n.login-button:hover {\n  background-color: #0056b3; /* Darker shade on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
