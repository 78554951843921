import React, { useState, useEffect, useRef } from 'react';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Cog6ToothIcon, QuestionMarkCircleIcon, MicrophoneIcon, ChatBubbleLeftRightIcon, ChartPieIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import LiveLot from './LiveLot';
import Conversations from './Conversations';
import Insights from './Insights';
import Scoreboard from './Scoreboard';
import ConversationDetail from './ConversationDetail';
import Login from './Login';
import axiosInstance from './axiosInstance';
import { API_BASE_URL } from './config'; // Import API_BASE_URL

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname.slice(1) || 'livelot');
  const [profilePic, setProfilePic] = useState<string | null>(null); // State for profile picture
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      fetchProfilePic(); // Fetch profile picture when logged in
    }
  }, [navigate]);

  const fetchProfilePic = async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/profile_pic`);

      if (response.status === 200 && response.data.profile_filename) {
        console.log('Profile picture data:', response.data);
        
        // Fetch the actual image data
        const imageResponse = await axiosInstance.get(`${API_BASE_URL}/profile_pics/${response.data.profile_filename}`, {
          responseType: 'blob'
        });

        if (imageResponse.status === 200) {
          // Create a URL for the image blob
          const imageUrl = URL.createObjectURL(imageResponse.data);
          setProfilePic(imageUrl);
        } else {
          console.error('Failed to fetch profile picture image');
          setProfilePic(null);
        }
      } else {
        console.error('Invalid response format or missing profile_filename');
        setProfilePic(null);
      }
    } catch (error) {
      console.error('Failed to fetch profile picture:', error);
      setProfilePic(null);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setProfilePic(null);
    setShowDropdown(false);
    navigate('/login');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const tabs = [
    { name: 'Live Lot', key: 'livelot', icon: <MicrophoneIcon className="h-5 w-5 mr-2" /> },
    { name: 'Conversations', key: 'conversations', icon: <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" /> },
    { name: 'Insights', key: 'insights', icon: <ChartPieIcon className="h-5 w-5 mr-2" /> },
    { name: 'Scoreboard', key: 'scoreboard', icon: <PresentationChartLineIcon className="h-5 w-5 mr-2" /> },
  ];

  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-gray-900 shadow-md">
        <div className="flex justify-between h-16 items-center px-4 sm:px-6 lg:px-8">
          <div className="flex items-center space-x-4">
            <img className="h-8 w-auto" src="/dealers-logo.png" alt="Your logo" />
            <span className="text-green-400 font-semibold text-lg ml-1 mr-6">Dealership Radar</span>
            <div className="hidden sm:flex sm:space-x-8">
              {tabs.map((tab) => (
                <Link
                  key={tab.key}
                  to={`/${tab.key}`}
                  className={`${
                    activeTab === tab.key
                      ? 'border-indigo-500 text-white'
                      : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-200'
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  onClick={() => setActiveTab(tab.key)}
                >
                  {tab.icon}
                  {tab.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <img className="h-8 w-auto logo-lighten" src="/ford-logo.png" alt="Customer logo" />
            <span className="text-gray-300 font-semibold">Serramonte Ford</span>
            <div className="hidden sm:flex sm:items-center space-x-2">
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="relative" ref={dropdownRef}>
                {profilePic ? (
                  <button 
                    className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <img className="h-6 w-6 rounded-full" src={profilePic} alt="Profile" />
                  </button>
                ) : null}
                {showDropdown && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <button
                      onClick={handleLogout}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="flex-1 overflow-y-auto">
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/livelot" element={<LiveLot />} /> {/* Removed showLiveTranscripts */}
            <Route path="/conversations" element={<Conversations showRecordings={true} />} />
            <Route path="/conversations/:id" element={<ConversationDetail />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/scoreboard" element={<Scoreboard />} />
            <Route path="/" element={<LiveLot />} /> {/* Removed showLiveTranscripts */}
          </Routes>
        </div>
      </main>
    </div>
  );
};

export default App;