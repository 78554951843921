import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { API_BASE_URL } from './config';
import './RepList.css';

interface Rep {
    id: string;
    name: string;
    profile_filename: string;
}

interface RepListProps {
    setSelectedRep: (rep: { id: number | null, name: string | null }) => void;
    selectedRep: number | null;
    lastMessageTimestamps: { [repId: number]: number }; // Add lastMessageTimestamps prop
}

const RepList: React.FC<RepListProps> = ({ setSelectedRep, selectedRep, lastMessageTimestamps }) => {
    const [reps, setReps] = useState<Rep[]>([]);
    const [profilePicSrcs, setProfilePicSrcs] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        fetchReps();
    }, []);

    const fetchReps = async () => {
        try {
            const response = await axiosInstance.get<Rep[]>(`${API_BASE_URL}/api/reps`);
            setReps(response.data);
            fetchProfilePics(response.data);
        } catch (error) {
            console.error('Error fetching reps:', error);
        }
    };

    const fetchProfilePics = async (reps: Rep[]) => {
        const profilePicPromises = reps.map(async (rep) => {
            try {
                const response = await axiosInstance.get(`${API_BASE_URL}/profile_pics/${rep.profile_filename}`, { responseType: 'blob' });
                const profilePicUrl = URL.createObjectURL(response.data);
                setProfilePicSrcs((prev) => ({ ...prev, [rep.id]: profilePicUrl }));
            } catch (error) {
                console.error(`Error fetching profile picture for ${rep.name}:`, error);
            }
        });
        await Promise.all(profilePicPromises);
    };

    const getStatus = (repId: number) => {
        const lastMessageTime = lastMessageTimestamps[repId];
        const currentTime = Date.now();
        const isLive = lastMessageTime && (currentTime - lastMessageTime) <= 30000; // 30 seconds
        return isLive ? 'Live' : 'Offline';
    };

    const getStatusColor = (repId: number) => {
        const lastMessageTime = lastMessageTimestamps[repId];
        const currentTime = Date.now();
        const isLive = lastMessageTime && (currentTime - lastMessageTime) <= 30000; // 30 seconds
        return isLive ? 'green-dot' : 'red-dot';
    };

    return (
        <div className="left-side">
            {reps.map((rep) => (
                <div
                    key={rep.id}
                    className={`rep-row ${selectedRep === Number(rep.id) ? 'selected' : ''}`} // Add selected class
                    onClick={() => setSelectedRep({ id: Number(rep.id), name: rep.name })} // Set selected rep on click
                >
                    <div className="rep-main">
                        <div className="rep-image">
                            {profilePicSrcs[rep.id] && (
                                <img src={profilePicSrcs[rep.id]} alt={rep.name} />
                            )}
                        </div>
                        <div className="rep-details">
                            <span className="rep-name">{rep.name}</span> {/* Made name bigger */}
                        </div>
                        <div className="rep-status-container">
                            <span className={`rep-status ${getStatusColor(Number(rep.id))}`}></span>
                            <span className="rep-status-text">{getStatus(Number(rep.id))}</span> {/* Updated status text */}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RepList;