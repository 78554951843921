import React, { useState } from 'react';
import RepList from './RepList';
import LiveTranscripts from './LiveTranscripts';
import './LiveLot.css';

interface SelectedRep {
    id: number | null;
    name: string | null;
}

const LiveLot: React.FC = () => {
    const [showLiveTranscripts, setShowLiveTranscripts] = useState<boolean>(true);
    const [selectedRep, setSelectedRep] = useState<SelectedRep>({ id: null, name: null }); // Update state to store both id and name
    const [lastMessageTimestamps, setLastMessageTimestamps] = useState<{ [repId: number]: number }>({}); // Add state for last message timestamps

    const updateLastMessageTimestamp = (repId: number) => {
        setLastMessageTimestamps(prev => ({
            ...prev,
            [repId]: Date.now()
        }));
    };

    return (
        <div className="live-lot">
            <RepList setSelectedRep={setSelectedRep} selectedRep={selectedRep.id} lastMessageTimestamps={lastMessageTimestamps} /> {/* Pass setSelectedRep, selectedRep.id, and lastMessageTimestamps */}
            <div className="vertical-line"></div>
            <LiveTranscripts showLiveTranscripts={showLiveTranscripts} selectedRep={selectedRep} updateLastMessageTimestamp={updateLastMessageTimestamp} /> {/* Pass selectedRep and updateLastMessageTimestamp */}
        </div>
    );
};

export default LiveLot;