import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import { formatDistanceToNow } from 'date-fns';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './Conversations.css';
import { API_BASE_URL } from './config';

interface ConversationsProps {
    showRecordings?: boolean;
}

interface AnnotatedTranscriptItem {
    relative_speaker_id: number,
    relative_start_time: number,
    relative_end_time: number,
    text: string;
}

interface RecordingEntry {
    id: number;
    identifier: string;
    timestamp: string;
    filename: string;
    status: string;
    formatted_transcript: string;
    annotated_transcript_items: AnnotatedTranscriptItem[];
    gen_title: string;
    rep_id: string;
    rep_name: string;
    gen_summary: string;
    profile_filename: string;
}

interface RepOption {
    value: string;
    label: string;
    profile_filename: string;
}

interface PaginatedResponse {
    recordings: RecordingEntry[];
    total: number;
    page: number;
    per_page: number;
}

const Conversations: React.FC<ConversationsProps> = ({ showRecordings = false }) => {
    const [recordings, setRecordings] = useState<RecordingEntry[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [repFilter, setRepFilter] = useState<string[]>([]);
    const [repOptions, setRepOptions] = useState<RepOption[]>([]);
    const [profilePicSrcs, setProfilePicSrcs] = useState<{ [key: string]: string }>({});
    const [audioSrcs, setAudioSrcs] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        fetchReps();
        fetchRecordings(currentPage);
    }, [currentPage, startDate, endDate, repFilter]);

    const fetchReps = async () => {
        try {
            const response = await axiosInstance.get<{ id: string, name: string, profile_filename: string }[]>(`${API_BASE_URL}/api/reps`);
            setRepOptions(response.data.map(rep => ({ value: rep.id, label: rep.name, profile_filename: rep.profile_filename })));
        } catch (error) {
            console.error('Error fetching reps:', error);
        }
    };

    const fetchRecordings = async (page: number) => {
        try {
            const response = await axiosInstance.get<PaginatedResponse>(`${API_BASE_URL}/api/recordings`, {
                params: {
                    page,
                    start_date: startDate ? startDate.toISOString() : undefined,
                    end_date: endDate ? endDate.toISOString() : undefined,
                    rep: repFilter.join(',')
                }
            });
            setRecordings(response.data.recordings);
            setTotalPages(Math.ceil(response.data.total / response.data.per_page));
            fetchMedia(response.data.recordings); // Fetch media after recordings are set
        } catch (error) {
            console.error('Error fetching recordings:', error);
        }
    };

    const fetchMedia = async (recordings: RecordingEntry[]) => {
        const profilePicPromises = recordings.map(async (recording) => {
            try {
                const response = await axiosInstance.get(`${API_BASE_URL}/profile_pics/${recording.profile_filename}`, { responseType: 'blob' });
                const profilePicUrl = URL.createObjectURL(response.data);
                setProfilePicSrcs((prev) => ({ ...prev, [recording.id]: profilePicUrl }));
            } catch (error) {
                console.error(`Error fetching profile picture for ${recording.rep_name}:`, error);
            }
        });

        const audioPromises = recordings.map(async (recording) => {
            try {
                const response = await axiosInstance.get(`${API_BASE_URL}/audio/${recording.filename}`, { responseType: 'blob' });
                const audioUrl = URL.createObjectURL(response.data);
                setAudioSrcs((prev) => ({ ...prev, [recording.id]: audioUrl }));
            } catch (error) {
                console.error(`Error fetching audio for ${recording.gen_title}:`, error);
            }
        });

        await Promise.all([...profilePicPromises, ...audioPromises]);
    };

    const customSingleValue = ({ data }: { data: RepOption }) => (
        <div className="custom-single-value">
            <img src={`${API_BASE_URL}/profile_pics/${data.profile_filename}`} alt={data.label} className="profile-pic" />
            {data.label}
        </div>
    );

    const customMultiValueLabel = ({ data }: { data: RepOption }) => (
        <div className="custom-multi-value-label">
            <img src={`${API_BASE_URL}/profile_pics/${data.profile_filename}`} alt={data.label} className="profile-pic" />
            {data.label}
        </div>
    );

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col p-6 space-y-4">
                <div className="flex space-x-4 items-center">
                    <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date || undefined)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="px-4 py-2 border rounded date-picker"
                        placeholderText="Start Date"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date || undefined)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        className="px-4 py-2 border rounded date-picker"
                        placeholderText="End Date"
                    />
                    <Select
                        isMulti
                        options={repOptions}
                        value={repOptions.filter(option => repFilter.includes(option.value))}
                        onChange={(selectedOptions) => setRepFilter(selectedOptions.map(option => option.value))}
                        className="w-64"
                        placeholder="Rep Name"
                        components={{ SingleValue: customSingleValue, MultiValueLabel: customMultiValueLabel }}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#2d2d2d',
                                color: '#fff',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: '#2d2d2d',
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#3a3a3a' : '#2d2d2d',
                                color: '#fff',
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                backgroundColor: '#4a4a4a',
                            }),
                            multiValueLabel: (provided) => ({
                                ...provided,
                                color: '#fff',
                            }),
                        }}
                    />
                </div>
                <div className="flex flex-1 space-x-6 overflow-hidden">
                    {showRecordings && (
                        <div className="w-full space-y-6 overflow-auto">
                            <div className="grid grid-cols-1 gap-6">
                                {recordings.map((recording) => (
                                    <Link key={recording.id} to={`/conversations/${recording.id}`}>
                                        <div className="conversation-card card">
                                            <div className="flex justify-between items-start mb-2">
                                                <div className="text-2xl font-bold">{recording.gen_title}</div>
                                                <div className="flex flex-col items-end">
                                                    <div className="flex items-center">
                                                        {profilePicSrcs[recording.id] && (
                                                            <img src={profilePicSrcs[recording.id]} alt={recording.rep_name} className="profile-pic" />
                                                        )}
                                                        <div className="rep-name">{recording.rep_name}</div>
                                                    </div>
                                                    <div className="text-lg conversation-timestamp">
                                                        {formatDistanceToNow(new Date(recording.timestamp), { addSuffix: true })}
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="mb-4" style={{ whiteSpace: 'pre-wrap' }}>
                                                {recording.gen_summary}
                                            </p>
                                            {recording.status === 'COMPLETE' && (
                                                <div className="w-full">
                                                    {audioSrcs[recording.id] && (
                                                        <audio controls className="w-full">
                                                            <source src={audioSrcs[recording.id]} type="audio/wav" />
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 bg-dark-button text-white rounded disabled:bg-gray-300"
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="px-4 py-2 bg-dark-button text-white rounded disabled:bg-gray-300"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Conversations;