import React from 'react';

const Insights: React.FC = () => {
  return (
    <div className="text-center py-10">
      <h2 className="text-2xl font-bold mb-4">Insights</h2>
      <p>This is a placeholder for the Insights page.</p>
    </div>
  );
};

export default Insights;