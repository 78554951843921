import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import { formatDistanceToNow } from 'date-fns';
import ReactAudioPlayer from 'react-audio-player';
import { FaDownload, FaShareAlt } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import './ConversationDetail.css'; // Import the CSS file for shimmer effect
import { API_BASE_URL } from './config';

interface AnnotatedTranscriptItem {
    relative_speaker_id: number,
    relative_start_time: number,
    relative_end_time: number,
    text: string;
}

interface RecordingEntry {
    id: number;
    identifier: string;
    timestamp: string;
    filename: string;
    status: string;
    formatted_transcript: string;
    annotated_transcript_items: AnnotatedTranscriptItem[];
    gen_title: string;
    gen_summary: string;
    rep_id: string;
    rep_name: string;
    profile_filename: string;
    gen_magic_summary: string;
    gen_objections: string;
    gen_metrics: string;
    gen_feedback: string;
    gen_speaker_assignment: { [key: string]: string }
}

const ConversationDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [recording, setRecording] = useState<RecordingEntry | null>(null);
    const [activeTab, setActiveTab] = useState('Magic Summary');
    const [loading, setLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [tabContent, setTabContent] = useState<string>('');
    const [tabLoaded, setTabLoaded] = useState<{ [key: string]: boolean }>({});
    const audioPlayerRef = useRef<ReactAudioPlayer>(null);
    const transcriptRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const lastScrollTimeRef = useRef<number>(0);
    const currentTabRef = useRef<string>('Magic Summary');
    const initialLoadRef = useRef<boolean>(true);
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [profilePicSrc, setProfilePicSrc] = useState<string | null>(null);

    useEffect(() => {
        fetchRecording();
    }, [id]);

    useEffect(() => {
        if (audioPlayerRef.current) {
            intervalRef.current = setInterval(handleAudioTimeUpdate, 50);
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [audioPlayerRef.current]);

    const fetchRecording = async () => {
        try {
            const response = await axiosInstance.get<RecordingEntry>(`${API_BASE_URL}/api/recordings/${id}`);
            setRecording(response.data);
            setLoading(false);
            // Fetch audio and profile picture
            fetchMedia(response.data);
            // Set initial tab content to "Magic Summary" with animation
            if (initialLoadRef.current) {
                setTimeout(() => {
                    renderTabContent(response.data.gen_magic_summary, 'Magic Summary');
                }, 0); // Ensure the content is set after the state update
                initialLoadRef.current = false;
            }
        } catch (error) {
            console.error('Error fetching recording:', error);
        }
    };

    const fetchMedia = async (recording: RecordingEntry) => {
        try {
            const audioResponse = await axiosInstance.get(`${API_BASE_URL}/audio/${recording.filename}`, { responseType: 'blob' });
            const audioUrl = URL.createObjectURL(audioResponse.data);
            setAudioSrc(audioUrl);

            const profilePicResponse = await axiosInstance.get(`${API_BASE_URL}/profile_pics/${recording.profile_filename}`, { responseType: 'blob' });
            const profilePicUrl = URL.createObjectURL(profilePicResponse.data);
            setProfilePicSrc(profilePicUrl);
        } catch (error) {
            console.error('Error fetching media:', error);
        }
    };

    const handleAudioTimeUpdate = () => {
        if (!isPlaying) return;

        const now = Date.now();
        if (now - lastScrollTimeRef.current < 200) {
            return;
        }
        lastScrollTimeRef.current = now;

        if (audioPlayerRef.current && transcriptRef.current && recording) {
            const currentTime = audioPlayerRef.current.audioEl.current?.currentTime || 0;
            const lastItemBeforeCurrentTime = recording.annotated_transcript_items
                .slice()
                .reverse()
                .find(item => item.relative_start_time <= currentTime);

            if (lastItemBeforeCurrentTime) {
                const element = document.getElementById(`transcript-item-${lastItemBeforeCurrentTime.relative_start_time}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    };

    const handleSeeked = () => {
        if (audioPlayerRef.current && transcriptRef.current && recording) {
            const currentTime = audioPlayerRef.current.audioEl.current?.currentTime || 0;
            const lastItemBeforeCurrentTime = recording.annotated_transcript_items
                .slice()
                .reverse()
                .find(item => item.relative_start_time <= currentTime);

            if (lastItemBeforeCurrentTime) {
                const element = document.getElementById(`transcript-item-${lastItemBeforeCurrentTime.relative_start_time}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'auto', block: 'start' });
                }
            }
        }
    };

    const handleTranscriptItemClick = (startTime: number) => {
        if (audioPlayerRef.current && audioPlayerRef.current.audioEl.current) {
            audioPlayerRef.current.audioEl.current.currentTime = startTime;
            audioPlayerRef.current.audioEl.current.play();
        }
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleDownload = () => {
        if (recording) {
            const link = document.createElement('a');
            link.href = `${API_BASE_URL}/audio/${recording.filename}`;
            link.download = recording.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleShare = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            alert('Copied to clipboard');
        });
    };

    const renderTabContent = (content: string, tab: string) => {
        if (!content) return; // Ensure content is not empty or undefined
        let index = -1;
        const words = content.split(' ').filter(word => word); // Filter out any empty strings
        setTabContent(''); // Clear previous content
        const interval = setInterval(() => {
            if (index < words.length - 1 && currentTabRef.current === tab) {
                setTabContent(prev => (prev ? prev + ' ' : '') + words[index]);
                index++;
            } else {
                clearInterval(interval);
            }
        }, 50);
    };

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        currentTabRef.current = tab;
        if (!tabLoaded[tab]) {
            setLoading(true);
            setTabContent('');
            setTimeout(() => {
                setLoading(false);
                setTabLoaded(prev => ({ ...prev, [tab]: true }));
                switch (tab) {
                    case 'Magic Summary':
                        renderTabContent(recording?.gen_magic_summary || '', tab);
                        break;
                    case 'Objections':
                        renderTabContent(recording?.gen_objections || '', tab);
                        break;
                    case 'Metrics':
                        renderTabContent(recording?.gen_metrics || '', tab);
                        break;
                    case 'Feedback':
                        renderTabContent(recording?.gen_feedback || '', tab);
                        break;
                    default:
                        break;
                }
            }, 500); // Simulate loading time
        } else {
            switch (tab) {
                case 'Magic Summary':
                    setTabContent(recording?.gen_magic_summary || '');
                    break;
                case 'Objections':
                    setTabContent(recording?.gen_objections || '');
                    break;
                case 'Metrics':
                    setTabContent(recording?.gen_metrics || '');
                    break;
                case 'Feedback':
                    setTabContent(recording?.gen_feedback || '');
                    break;
                default:
                    break;
            }
        }
    };

    if (!recording) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col h-full p-6 no-scroll">
            <header className="text-3xl font-bold mb-4">{recording.gen_title}</header>
            <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                    {profilePicSrc && (
                        <img src={profilePicSrc} alt={recording.rep_name} className="profile-pic" />
                    )}
                    <div className="rep-name">{recording.rep_name}</div>
                </div>
                <div className="text-sm">{formatDistanceToNow(new Date(recording.timestamp))} ago</div>
                <div className="flex space-x-4">
                    <button className="flex items-center text-gray-700" onClick={handleDownload}>
                        <FaDownload className="mr-2" /> Download
                    </button>
                    <button className="flex items-center text-gray-700" onClick={handleShare}>
                        <FaShareAlt className="mr-2" /> Share
                    </button>
                </div>
            </div>
            <div className="flex flex-row h-full overflow-hidden">
                <div className="flex flex-col w-3/5 pr-4 h-full overflow-y-auto">
                    <div className="flex mb-4 border-b border-gray-300">
                        {['Magic Summary', 'Objections', 'Metrics', 'Feedback'].map(tab => (
                            <div
                                key={tab}
                                className={`px-4 py-2 cursor-pointer ${activeTab === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-700'}`}
                                onClick={() => handleTabClick(tab)}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>
                    <div className="flex-grow mb-4 overflow-y-auto markdown-content tab-content" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            <ReactMarkdown>{tabContent}</ReactMarkdown>
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-2/5 pl-4 border-l border-gray-300 h-full">
                    <div className="text-xl font-bold mb-4">Transcript</div>
                    <div className="flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }} ref={transcriptRef}>
                        <div className="text-gray-700 mb-6" style={{ whiteSpace: 'pre-wrap' }}>
                            {recording.annotated_transcript_items.reduce((acc, item, index, items) => {
                                const colors = ['#4A90E2', '#7ED321', '#FFC107', '#9C27B0', '#F44336'];
                                const color = colors[item.relative_speaker_id % colors.length];
                                const formatTime = (time: number) => {
                                    const minutes = Math.floor(time / 60);
                                    const seconds = Math.floor(time % 60);
                                    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                                };
                                const showTimestamp = index === 0 || item.relative_speaker_id !== items[index - 1].relative_speaker_id;
                                if (showTimestamp) {
                                    acc.push(
                                        <div key={`timestamp-${item.relative_start_time}`} className="transcript-timestamp">
                                            {formatTime(item.relative_start_time)}
                                            <span className="transcript-speaker" style={{ color }}>
                                                {recording.gen_speaker_assignment[item.relative_speaker_id.toString()]}
                                            </span>
                                        </div>
                                    );
                                }
                                if (index === 0 || item.relative_speaker_id !== items[index - 1].relative_speaker_id) {
                                    acc.push(
                                        <p
                                            key={`paragraph-${item.relative_start_time}`}
                                            id={`transcript-item-${item.relative_start_time}`}
                                            className="transcript-item"
                                            style={{ color }}
                                            onClick={() => handleTranscriptItemClick(item.relative_start_time)}
                                        >
                                            {item.text}
                                        </p>
                                    );
                                } else {
                                    acc[acc.length - 1] = React.cloneElement(acc[acc.length - 1], {}, acc[acc.length - 1].props.children + ' ' + item.text);
                                }
                                return acc;
                            }, [] as JSX.Element[])}
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-media-player">
                {audioSrc && (
                    <ReactAudioPlayer
                        ref={audioPlayerRef}
                        src={audioSrc}
                        controls
                        style={{ width: '100%' }}
                        onListen={handleAudioTimeUpdate}
                        onSeeked={handleSeeked}
                        onPlay={handlePlay}
                        onPause={handlePause}
                    />
                )}
            </div>
        </div>
    );
};

export default ConversationDetail;