import React, { useState, useEffect, useRef, useCallback } from 'react';
import { WS_BASE_URL } from './config';
import './LiveTranscripts.css';

interface TranscriptFragment {
    rep_id: number;
    text: string;
    relative_speaker_id: number;
    relative_start_time: number;
    relative_end_time: number;
}

interface SelectedRep {
    id: number | null;
    name: string | null;
}

interface LiveTranscriptsProps {
    showLiveTranscripts: boolean;
    selectedRep: SelectedRep; // Use SelectedRep for selectedRep
    updateLastMessageTimestamp: (repId: number) => void; // Add updateLastMessageTimestamp prop
}

const LiveTranscripts: React.FC<LiveTranscriptsProps> = ({ showLiveTranscripts, selectedRep, updateLastMessageTimestamp }) => {
    const [transcripts, setTranscripts] = useState<{ [repId: number]: string }>({});
    const wsRef = useRef<WebSocket | null>(null);
    const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const connectionId = useRef<number>(Math.random()); // Unique identifier for each connection

    const cleanupWebSocket = useCallback(() => {
        if (wsRef.current) {
            console.log(`Closing WebSocket connection (connectionId: ${connectionId.current})`);
            wsRef.current.close();
            wsRef.current = null;
        }
        if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
            pingIntervalRef.current = null;
        }
    }, []);

    const setupWebSocket = useCallback(() => {
        if (wsRef.current) {
            console.log(`WebSocket already exists (connectionId: ${connectionId.current})`);
            return;
        }

        console.log(`Setting up new WebSocket (connectionId: ${connectionId.current})`);
        wsRef.current = new WebSocket(`${WS_BASE_URL}/frontend`);

        wsRef.current.onopen = () => {
            console.log(`WebSocket connection opened (connectionId: ${connectionId.current})`);

            pingIntervalRef.current = setInterval(() => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    wsRef.current.send(JSON.stringify({ type: 'ping' }));
                }
            }, 10000);
        };

        wsRef.current.onclose = () => {
            console.log(`WebSocket connection closed (connectionId: ${connectionId.current})`);
            cleanupWebSocket();
            if (showLiveTranscripts) {
                setTimeout(setupWebSocket, 5000);
            }
        };

        wsRef.current.onmessage = (event) => {
            const data: TranscriptFragment = JSON.parse(event.data);
            console.log(`Received message through WS (connectionId: ${connectionId.current}):`, data);
            setTranscripts(prev => {
                const repTranscripts = prev[data.rep_id] || '';
                return {
                    ...prev,
                    [data.rep_id]: repTranscripts + ' ' + data.text
                };
            });
            updateLastMessageTimestamp(data.rep_id);
        };
    }, [showLiveTranscripts, updateLastMessageTimestamp]);

    useEffect(() => {
        if (showLiveTranscripts) {
            setupWebSocket();
        } else {
            cleanupWebSocket();
        }

        return () => {
            cleanupWebSocket();
        };
    }, [showLiveTranscripts, setupWebSocket, cleanupWebSocket]);

    return (
        <div className="right-side">
            {showLiveTranscripts && (
                <div className="live-transcripts">
                    <h2 className="live-transcript-header">
                        {selectedRep.id !== null ? `Live Transcript for ${selectedRep.name}` : 'Select a Rep'}
                    </h2>
                    {selectedRep.id !== null && transcripts[selectedRep.id] && (
                        <p className="transcript">{transcripts[selectedRep.id]}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default LiveTranscripts;