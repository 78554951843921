import React from 'react';

const Scoreboard: React.FC = () => {
  return (
    <div className="text-center py-10">
      <h2 className="text-2xl font-bold mb-4">Scoreboard</h2>
      <p>This is a placeholder for the Scoreboard page.</p>
    </div>
  );
};

export default Scoreboard;