// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.live-lot {
    display: flex;
    flex-direction: row; /* Ensure the layout is horizontal */
    height: 100%;
}

.live-lot-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.vertical-line {
    width: 1px;
    background-color: #ccc;
    margin: 0 10px; /* Adjusted margin to be closer to the rep rows */
}

.right-side {
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/LiveLot.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,oCAAoC;IACzD,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,cAAc,EAAE,iDAAiD;AACrE;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":["html, body, #root {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n}\n\n.live-lot {\n    display: flex;\n    flex-direction: row; /* Ensure the layout is horizontal */\n    height: 100%;\n}\n\n.live-lot-container {\n    display: flex;\n    width: 100%;\n    height: 100%;\n}\n\n.vertical-line {\n    width: 1px;\n    background-color: #ccc;\n    margin: 0 10px; /* Adjusted margin to be closer to the rep rows */\n}\n\n.right-side {\n    flex-grow: 1;\n    padding: 10px;\n    box-sizing: border-box;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
