import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {API_BASE_URL} from './config'; // Import the configuration file
import './Login.css'; // Import a CSS file for styling

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.access_token); // Store the token
      navigate('/livelot');
    } else {
      alert('Login failed');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card card"> {/* Added a card container */}
        <form onSubmit={handleLogin}>
          <div>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input" // Added class for styling
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input" // Added class for styling
            />
          </div>
          <button type="submit" className="login-button">Login</button> {/* Changed button style */}
        </form>
      </div>
    </div>
  );
};

export default Login;