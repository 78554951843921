// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-card {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #2d2d2d; /* Darker background for card */
    color: #fff; /* White text for better contrast */
}

.conversation-timestamp {
    color: var(--muted-text); /* Use muted text color */
    font-size: 0.8em;
}

.rep-name {
    color: var(--muted-text); /* Use muted text color */
    font-weight: normal; /* Unbold the rep name */
    margin-left: 8px; /* Add some space between the profile picture and the name */
    font-size: 1em; /* Make the rep name text larger */
}

.bg-dark-button {
    background-color: #2d2d2d; /* Darker background for buttons */
    color: #fff; /* White text for better contrast */
}

.date-picker {
    background-color: #2d2d2d; /* Darker background for date picker */
    color: #fff; /* White text for better contrast */
}

.custom-single-value, .custom-multi-value-label {
    display: flex;
    align-items: center;
}

.profile-pic {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Conversations.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB,EAAE,+BAA+B;IAC1D,WAAW,EAAE,mCAAmC;AACpD;;AAEA;IACI,wBAAwB,EAAE,yBAAyB;IACnD,gBAAgB;AACpB;;AAEA;IACI,wBAAwB,EAAE,yBAAyB;IACnD,mBAAmB,EAAE,wBAAwB;IAC7C,gBAAgB,EAAE,4DAA4D;IAC9E,cAAc,EAAE,kCAAkC;AACtD;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,WAAW,EAAE,mCAAmC;AACpD;;AAEA;IACI,yBAAyB,EAAE,sCAAsC;IACjE,WAAW,EAAE,mCAAmC;AACpD;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".conversation-card {\n    padding: 10px;\n    border-radius: 8px;\n    margin-bottom: 10px;\n    background-color: #2d2d2d; /* Darker background for card */\n    color: #fff; /* White text for better contrast */\n}\n\n.conversation-timestamp {\n    color: var(--muted-text); /* Use muted text color */\n    font-size: 0.8em;\n}\n\n.rep-name {\n    color: var(--muted-text); /* Use muted text color */\n    font-weight: normal; /* Unbold the rep name */\n    margin-left: 8px; /* Add some space between the profile picture and the name */\n    font-size: 1em; /* Make the rep name text larger */\n}\n\n.bg-dark-button {\n    background-color: #2d2d2d; /* Darker background for buttons */\n    color: #fff; /* White text for better contrast */\n}\n\n.date-picker {\n    background-color: #2d2d2d; /* Darker background for date picker */\n    color: #fff; /* White text for better contrast */\n}\n\n.custom-single-value, .custom-multi-value-label {\n    display: flex;\n    align-items: center;\n}\n\n.profile-pic {\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
