// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-transcripts {
    height: 100%;
    overflow-y: auto;
    font-family: 'Verdana', sans-serif;
}

.transcript {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    font-family: 'Verdana', sans-serif;
}

.live-transcript-header {
    font-size: 2em; /* Make the header much larger */
    font-weight: bold;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/LiveTranscripts.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,cAAc,EAAE,gCAAgC;IAChD,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".live-transcripts {\n    height: 100%;\n    overflow-y: auto;\n    font-family: 'Verdana', sans-serif;\n}\n\n.transcript {\n    margin-bottom: 10px;\n    padding: 10px;\n    border-radius: 8px;\n    font-family: 'Verdana', sans-serif;\n}\n\n.live-transcript-header {\n    font-size: 2em; /* Make the header much larger */\n    font-weight: bold;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
